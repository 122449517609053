/* eslint-disable import/prefer-default-export */
import { VueConstructor, VNodeData } from 'vue'
import { Vue } from 'vue-demi'

import { VFileInput, VTextField } from 'vuetify/lib'

import { SchemaField, SchemaFieldType } from './types'

function getComponent(type: SchemaFieldType): VueConstructor<Vue> {
  let component: VueConstructor<Vue> = VTextField

  switch (type) {
    case 'file':
      component = VFileInput
      break
    default:
      component = VTextField
      break
  }

  return component
}

// eslint-disable-next-line
function getOptions(type: SchemaFieldType): VNodeData {
  return {}
}

export function getComponentOptionsByField(field: SchemaField) {
  const component = getComponent(field.type)
  const options = getOptions(field.type)

  return { component, options }
}

export function preFillFieldValueByType(type: SchemaFieldType) {
  switch (type) {
    case 'number':
      return 0
    case 'file':
      return []
    default:
      return ''
  }
}
