















































































import {
  ref,
  defineComponent,
} from 'vue-demi'

interface Props extends Record<string, any> {
  foo: string
}

export default defineComponent<Props>({
  components: {},

  props: {},

  setup() {
    const modal = ref(false)

    const variants = [
      {
        title: 'Свободен',
        value: 'AVAILABLE',
      },
      {
        title: 'В работе',
        value: 'IN_PROGRESS',
      },
    ]

    const radioGroup = ref('AVAILABLE')

    return {
      modal,
      variants,
      radioGroup,
    }
  },
})

