/* eslint-disable import/prefer-default-export */
import { VNode } from 'vue'

export function constructSlots(slots: Readonly<{ [key in string]?: (...args: any[]) => VNode[]; }>) {
  const headerSlot = slots && slots.default && slots.default()
  const defaultSlot = slots && slots.header && slots.header()
  const footerSlot = slots && slots.footer && slots.footer()

  return {
    headerSlot,
    defaultSlot,
    footerSlot,
  }
}
