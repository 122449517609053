/* eslint-disable no-restricted-syntax */

import {
  h,
  ref,
  set,
  watch,
  toRefs,
  onMounted,
  defineComponent,
} from 'vue-demi'

import {
  VBtn,
  VCardActions,
  VCardText, VForm,
} from 'vuetify/lib'

import { VForm as IVForm } from '@/types/vuetify'

import { unValidateForm, validateForm } from '@/utils/validators'

import { FormHandler, FormSchema, SchemaField } from './types'

import { getComponentOptionsByField, preFillFieldValueByType } from './options'
import { constructSlots } from './slots'

interface Props extends Record<string, any> {
  state: Record<string, any>
  schema: FormSchema
  submitHandler: FormHandler
  resetHandler: FormHandler
}

export default defineComponent<Props>({
  props: {
    schema: {
      type: Object,
      required: true,
    },

    submitHandler: {
      type: Function,
      required: true,
    },

    resetHandler: {
      type: Function,
      required: true,
    },

    state: {
      type: Object,
      required: true,
    },
  },

  setup(props, {
    emit, slots,
    attrs, refs,
  }) {
    const { schema, state } = toRefs(props)

    const localState = ref<Record<string, any>>({})

    const { defaultSlot, headerSlot, footerSlot } = constructSlots(slots)

    onMounted(() => {
      schema.value.fields.forEach(field => {
        const value = preFillFieldValueByType(field.type)
        set(localState.value, field.name, value)
      })
    })

    function processFields(fields: SchemaField[]) {
      return fields.map(field => {
        const { component } = getComponentOptionsByField(field)

        return h(
          component,
          {
            key: field.name,
            props: {
              value: localState.value[field.name],
              outlined: true,
              rounded: true,
              placeholder: field.label,
              rules: field.rules,
            },
            on: {
              input: (v: string) => { set(localState.value, field.name, v) },
              change: (v: string) => { set(localState.value, field.name, v) },
            },
          },
        )
      })
    }

    // ? протечка пропа?
    watch(
      () => state.value,
      v => { localState.value = v },
      { immediate: true, deep: true },
    )

    return () => h(
      VForm,
      {
        attrs,
        ref: 'form',
        on: {
          submit: (event: SubmitEvent) => {
            event.preventDefault()
            validateForm(refs.form as IVForm)
            emit('submit', state.value, event)
          },
          reset: (event: SubmitEvent) => {
            event.preventDefault()
            unValidateForm(refs.form as IVForm)
            emit('reset', state.value, event)
          },
        },
      },
      [
        headerSlot,
        h(
          VCardText,
          processFields(schema.value.fields),
        ),
        defaultSlot ? h(VCardText, [defaultSlot]) : null,
        h(
          VCardActions,
          { staticClass: 'row justify-center ma-0 py-5' },
          [
            h(
              VBtn,
              {
                attrs: {
                  color: 'primary', depressed: true, rounded: true, type: 'submit', width: 120,
                },
              },
              'Отправить',
            ),
            h(
              VBtn,
              {
                attrs: {
                  depressed: true,
                  rounded: true,
                  type: 'reset',
                  width: 120,
                },
                on: {
                  click: () => {
                    unValidateForm(refs.form as IVForm)
                    emit('update:state', {})
                    emit('reset')
                  },
                },
              },
              'Отмена',
            ),
          ],
        ),
        footerSlot,
      ],
    )
  },
})
