
































































import {
  ref,
  defineComponent,
} from 'vue-demi'

import { FormGenerator, FormSchema, SchemaField } from '@/components/form-generator'

import { required } from '@/utils/validators'

interface Props extends Record<string, any> {
  foo: string
}

const fields: SchemaField[] = [
  {
    type: 'text',
    name: 'title',
    label: 'Название',
    placeholder: 'Название',
    rules: [required],
  },
  {
    type: 'text',
    name: 'description',
    label: 'Описание',
    placeholder: 'Описание',
    rules: [required],
  },
  {
    type: 'file',
    name: 'files',
    label: 'Файл',
    placeholder: 'Файл',
    multiple: true,
  },
]

const items = [
  {
    id: 0,
    name: 'Все категории',
  },
  {
    id: 1,
    name: 'Ремонт и строительство',
    children: [
      { id: 2, name: 'Электромонтажные работы' },
      { id: 3, name: 'Сантехнические работы' },
      { id: 4, name: 'Отопление, водоснабжение, канализация' },
      { id: 101, name: 'Изоляционные работы' },
    ],
  },
  {
    id: 5,
    name: 'Уборка помещения',
  },
  {
    id: 6,
    name: 'Ремонт техники',
  },
]

export default defineComponent<Props>({
  components: {
    FormGenerator,
  },

  setup() {
    const modal = ref(false)

    const formState = ref<Record<string, any>>({})
    const formSchema: FormSchema = { fields }

    function onSubmit(state: Record<string, any>) {
      console.dir(state, { depth: 10 })
    }

    function onReset() {
      modal.value = false
      formState.value = {}
    }

    return {
      modal,
      items,
      fields,
      onSubmit,
      onReset,
      formState,
      formSchema,
    }
  },
})

